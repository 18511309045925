<template>
  <form
    action
    id="customer-form-date"
    data-vv-scope="date-time-form"
    @submit.prevent="validateForm('date-time-form')"
  >
    <v-row justify="center">
      <v-col cols="12" md="4" style="text-align: center">
        <h3
          style="
            text-transform: uppercase;
            font-weight: lighter;
            color: var(--v-primary-base);
          "
        >
          {{ $t("select", { name: "Dia y hora" }) }}:
        </h3>
        <v-date-picker
          first-day-of-week="1"
          v-model="customer.date"
          no-title
          :reactive="true"
          :allowed-dates="allowedDates"
          :min="today"
          color="primary"
          :locale="$i18n.locale"
          data-vv-validate-on="blur"
          v-validate="'required'"
          data-vv-name="date"
          :error-messages="errors.collect('date-time-form.date')"
          @change="fetchAppointentsByStudioTattoerDate"
        ></v-date-picker>
      </v-col>
      <v-col cols="12" md="8" v-if="customer.date" style="margin: auto">
        <v-row :justify="$vuetify.breakpoint.xs ? 'center' : ''">
          <v-col cols="6" md="2">
            <label style="line-height: 1">Horas</label>
            <v-select
              :items="hoursDate(customer.date)"
              outlined
              hide-details
              dense
              v-model="customer.time"
              :item-disabled="disabled"
            ></v-select>
          </v-col>
          <v-col
            v-if="false"
            cols="12"
            style="margin: auto; overflow: auto; text-align: center"
          >
            <template v-for="(h, i) in horario(customer.date)">
              <v-btn
                v-for="(b, j) in hours(h[0], h[1])"
                :key="'h-' + i + '-' + j"
                :outlined="customer.time != b"
                large
                elevation="0"
                dense
                class="mr-3 mb-4"
                :disabled="disabled(b)"
                :color="time_error ? 'red' : 'primary'"
                @click="
                  $set(customer, 'time', b);
                  time_error = false;
                "
                style="border-radius: 10px; height: 35px"
              >
                {{ b }}
              </v-btn>
            </template>
          </v-col>
          <v-col cols="12" style="margin: auto; overflow: auto">
            <div
              style="
                color: var(--v-primary-base);
                margin-left: 20px;
                z-index: 4;
              "
            >
              <v-checkbox
                v-if="!skip_validate"
                v-model="selected"
                v-validate="'required|is:true'"
                true-value="true"
                data-vv-name="share"
                false-value="false"
                :label="$t('appointments.check_consent')"
                :error-messages="errors.collect('date-time-form.share')"
                on-icon="mdi-check-circle"
                off-icon="mdi-circle-outline"
              >
                <template v-slot:label>
                  <div style="margin-top: 6px">
                    {{ $t("check_condicions") }}
                    <a
                      @click.stop
                      target="blank"
                      :href="studio.value == 'link' ? link.value : URL"
                    >
                      {{ $t("check_condicions_links") }}
                    </a>
                  </div>
                </template></v-checkbox
              >
            </div>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" md="8" v-else style="margin: auto; overflow: auto">
        <v-simple-table style="min-width: 600px">
          <thead>
            <tr>
              <th v-for="n in (1, 7)" :key="n" class="no-wrap">
                {{ $t("week_days")[n] }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td v-for="n in (1, 7)" :key="n">
                <p v-for="(h, i) in customer.studio.timetable[n - 1]" :key="i">
                  {{ h[0] }}-{{ h[1] }}
                </p>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
    <div class="pa-3 text-right">
      <v-btn
        color="primary"
        @click="$emit('back')"
        style="height: 30px; width: 100px"
        outlined
      >
        <v-icon>mdi-arrow-left</v-icon>
        {{ $t("back") }}
      </v-btn>
      <v-btn
        color="primary"
        type="submit"
        style="height: 30px; width: 100px"
        elevation="0"
        class="ml-2"
      >
        {{ $t("next") }}
        <v-icon>mdi-arrow-right</v-icon>
      </v-btn>
    </div>
  </form>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import * as moment from "moment";
export default {
  name: "DateTimeSelect",
  props: ["select", "skip_validate"],
  data() {
    return {
      today: null,
      appointments: [],
      time_error: false,
      dates: [],
      datesTattooer: [],
    };
  },
  mounted() {
    this.today = moment().format("YYYY-MM-DD");
    this.customer.date = moment().format("YYYY-MM-DD");
    this.fetchAppointentsByStudioTattoerDate();
    this.fetchTimeTables();
    if (this.$store.getters["auth/isTattooer"]) {
      this.dates = this.customerState.studio.vacations;
    } else {
      this.fetchAll();
    }
    /*this.fetchAllVacationsTattooer(
      this.customerState.tattooer.id,
      this.customerState.studio.id
    );*/
  },
  methods: {
    ...mapActions("timetables", ["getTimetables", "getTimetableResume"]),
    fetchTimeTables() {
      this.getTimetableResume({
        studio_id: this.customer.studio.id,
      });
      this.getTimetables({
        filters: {
          studio_id: this.customer.studio.id,
        },
      });
    },
    hoursDate(date) {
      let h = [];
      this.getDayTimetable()(
        date,
        this.customer.studio.id,
        this.customer.tattooer.id
      ).forEach((x) => {
        console.log(x);
        this.hours(x[0], x[1]).forEach((hs) => {
          if (!this.disabled(hs)) h.push(hs);
        });
      });
      return h;
    },
    ...mapActions("appointments", ["getAppointentsByStudioTattoerDate"]),
    fetchAppointentsByStudioTattoerDate() {
      this.getAppointentsByStudioTattoerDate({
        studio_id: this.customer.studio.id,
        tattooer_id: this.customer.tattooer.id,
        date: this.customer.date,
      }).then((data) => {
        this.appointments = data;
      });
    },

    async validateForm(scope) {
      if (this.skip_validate) {
        this.$emit("validated");
        return;
      }
      this.errors.clear();
      let val_time = true;
      if (!this.customer.time) {
        val_time = false;
        this.time_error = true;
      }
      this.$validator.validateAll(scope).then((result) => {
        //console.log("validate", result, val_time);
        if (result && val_time) {
          this.$emit("validated", this.studio);
        }
      });
    },
    ...mapActions("close_days", ["getAll"]),
    fetchAll() {
      this.getAll().then((data) => {
        if (data) {
          this.dates = data;
          console.log(this.dates);
        }
      });
    },
    ...mapActions("close_days_tattooer", ["getAllTattooer"]),
    fetchAllVacationsTattooer(tattooer, studio) {
      this.getAllTattooer({ tattooer_id: tattooer, studio_id: studio }).then(
        (data) => {
          if (data) {
            this.datesTattooer = data;
            console.log(this.datesTattooer);
          }
        }
      );
    },
    ...mapGetters("timetables", ["getDayTimetable"]),

    allowedDates(date) {
      /*console.log(
        "%c%s",
        "color: #733d00",
        "select",
        this.customer[this.select]
      );*/
      let a = this.dates.find((x) => {
        return x.start_date <= date && x.end_date >= date;
      });

      if (a) {
        console.log(a);
        return false;
      }
      if (this.customer.tattooer.id !== -1)
        if (
          this.customer.tattooer.vacation_tattooer &&
          this.customer.tattooer.vacation_tattooer.length > 0
        ) {
          let t = this.customer.tattooer.vacation_tattooer.find((x) => {
            return x.start_date <= date && x.end_date >= date;
          });
          if (t) {
            //console.log(t);
            return false;
          }
        }
      let h = this.getDayTimetable()(
        date,
        this.customer.studio.id,
        this.customer.tattooer.id
      );
      if (h) {
        return h.length > 0;
      }
      return false;
    },
    disabled(h) {
      //desactivar las horas ocupadas!!!
      h = this.customer.date + " " + h;
      h = new Date(h);
      let app = this.appointments.find((x) => {
        let time = x.available_dates.find((y) => {
          if (
            new Date(y.date).getTime() <= h.getTime() &&
            new Date(y.endDate).getTime() > h.getTime()
          )
            return true;
        });
        if (time) return true;
        return false;
      });

      if (app) return true;
      return false;
    },

    filter(date) {
      if (this.customer.tattooer.id !== -1)
        return this.customer[this.select].tattooer_studios.find((element) => {
          if (element.studio_id !== this.customer.studio.id) {
            return false;
          }
          if (element.to) {
            // comprovar que estigui al interval
            return element.from <= date && date <= element.to;
          } else {
            //comprovar que sigui mes gran que from
            return element.from <= date;
          }
        });
      return true;
    },

    horario(date) {
      if (date) {
        return this.timeTable(date)[this.dayOfWeek(date)];
      }
      return null;
    },
    timeTable(date) {
      if (this.filter(date) !== true && this.filter(date).tattooer_timetable)
        return this.filter(date).tattooer_timetable;
      return this.customer.studio.timetable;
    },
    dayOfWeek(date) {
      let d = new Date(date);

      d = d.getDay() - 1;
      if (d < 0) d = 6;
      return d;
    },

    hours(min, max) {
      let mins = [":00", ":15", ":30", ":45"];
      console.log(min, max);
      let hs = [];
      let mn = +min.split(":")[0];
      let mx = +max.split(":")[0];

      for (let i = mn; i < mx; i++) {
        mins.forEach((m) => {
          if (i < 10) hs.push("0" + i + m);
          else hs.push(i + m);
        });
      }
      return hs;
    },
    setDay() {
      let day = this.today;
      console.log("SETTING DAY!");
      if (this.customer.tattooer.id !== -1) {
        let relation = this.customer[this.select].tattooer_studios[0];
        console.log(relation);
        if (relation.from > this.today) {
          day = relation.from;
        }
      }
      console.log(day);
      this.customer.date = day;
      console.log(this.customer);
    },
  },
  computed: {
    ...mapState("inscriptions", ["tattooerState", "customerState"]),
    tattooer: {
      get() {
        return this.tattooerState;
      },
      set(value) {
        this.$store.commit("inscriptions/SET_TATTOOER", value);
      },
    },
    customer: {
      get() {
        return this.customerState;
      },
      set(value) {
        this.$store.commit("inscriptions/SET_CUSTOMER", value);
      },
    },
    studio() {
      let policy = this.customerState.studio.user.setting.find((x) => {
        return x.value == "link";
      });
      console.log(policy);

      return policy ? policy : "none";
    },
    link() {
      let policy = this.customerState.studio.user.setting.find((x) => {
        return x.key == "link_appointment";
      });
      console.log(policy);
      return policy;
    },
    URL() {
      return `${window.location.origin}/?#/inscription/policy/studio/${this.$route.params.token}`;
    },
  },
};
</script>
 
 

<style lang="sass">
#customer-form-date
  .v-input--selection-controls__input
    margin-top: 4px !important
  label
    color: var(--v-primary-base) !important
  .v-messages
    display: none
  .selected
    color: var(--v-primary-base) !important
</style>
